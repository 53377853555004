import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'reactstrap'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

import LogoBc from '../LogoBc'
import Link from '../Link';
import SocialLink from '../SocialLink';

import * as S from './styled'

const Footer = () => {
  const { site: { siteMetadata : about } } = useStaticQuery(
    graphql`
      query About {
        site {
          siteMetadata {
            title
            description
            address
            email
            phone
            instagran
            facebook
          }
        }
      }
    `
  )
  about.phoneLink = about.phone.replace(/\D/gim, '');
  // console.log(about);
  const varData = new Date();
  return (
    <S.Footer>
      <Container>
        <S.Row>
          <S.Div>
            <div>
              <LogoBc propLink/>
            </div>
            <span>{about.title} ​© {varData.getFullYear()} | {about.description}</span>
            <div>
              <SocialLink Url={about.facebook} Icon={FaFacebookSquare}/>
              <SocialLink Url={about.instagran} Icon={FaInstagram}/>
            </div>
          </S.Div>
          <S.Div>
            <Link to={`https://api.whatsapp.com/send?phone=55${about.phoneLink}`}>
              {about.phone}
            </Link>
            <Link to={`mailto:${about.email}`}>
              {about.email}
            </Link>
            <span>{about.address}</span>
            <span><br></br></span>
            <S.Developer>Desenvolvido por <Link to={`https://delttatecnologia.com.br`}>Deltta Tecnologia</Link></S.Developer>
          </S.Div>
        </S.Row>
      </Container>
    </S.Footer>
  )
}

export default Footer;