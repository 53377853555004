import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import * as S from './styled'

const LogoBc = ({ propLink }) => {
  const { logoImage } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "logo-bc.png" }) {
          childImageSharp {
            fixed(width: 127, height: 70) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  if (propLink) {
    return (
      <S.Link to="/">
        <S.LogoBcWrapper fixed={logoImage.childImageSharp.fixed} />
      </S.Link>
    )
  } else {
    return (
      <S.LogoBcWrapper fixed={logoImage.childImageSharp.fixed} />
    )
  }
}

LogoBc.defaultProps = {
  propLink: false,
}

LogoBc.propTypes = {
  propLink: PropTypes.bool,
}

export default LogoBc
