import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import bLink from '../Link'

export const Link = styled(bLink)`
  display: inline-block;
  line-height: inherit;
  white-space: nowrap;
  margin: ${props => props.margin+'px'};
`

export const LogoWrapper = styled(Img)`
  margin: ${props => props.margin+'px'};
  /* margin: 30px; */
  /* padding: 30px; */
  /* border-radius: 50%;
  height: 3.75rem;
  margin: auto;
  width: 3.75rem;

  ${media.lessThan('large')`
    height: 1.875rem;
    width: 1.875rem;
  `} */
`
