import styled from 'styled-components'
import bNavigation from 'reactstrap-json-nav'
import media from 'styled-media-query'
import {
  Container as bContainer,
  Nav as bNav,
} from 'reactstrap';
import bLink from '../Link'

export const Container = styled(bContainer)`
  display: flex;
  flex-direction: column;
  
  /* kesdev-tmp */
  /* background-color: #ff000021;  */
`

export const Link = styled(bLink)`
  display: inline-block;
  line-height: inherit;
  white-space: nowrap;
`
// color: #213552;
// color: #40829c;
// color: #258e91;

export const Navigation = styled(bNavigation)`
  display: inline-block;
  line-height: inherit;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  ${media.lessThan('medium')`
    flex-direction: column;
  `}

  a {
    color: #6c757d !important;
  }
  
  a:hover {
    color: #258e91 !important;
    background: #ffffff;
  }

  div {
    background: #ffffffcf;
    border: 0;
    left: 0;
    right: auto;
  }

  .dropdown-toggle::after{
    display: none;
  }

  ${media.lessThan('small')`
    .dropdown-item {
      font-size: 12px;
    }
  `}
`

export const Nav = styled(bNav)`
  display: inline-block;
  line-height: inherit;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  ${media.lessThan('medium')`
    flex-direction: column;
  `}

  a {
    color: #6c757d !important;
    font-weight: bold !important;
  }
  
  a:hover {
    color: #258e91 !important;
    background: #ffffff;
  }

  div {
    background: #ffffffcf;
    border: 0;
    left: 0;
    right: auto;
  }

  .dropdown-toggle::after{
    display: none;
  }

  ${media.lessThan('small')`
    .dropdown-item {
      font-size: 12px;
    }
  `}
`