import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavItem, 
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
} from 'reactstrap';

import Link from '../Link'
import Logo from '../Logo'

// import navigation from '../../data/navigation';

import * as S from './styled'

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="light" light expand="md">
      <S.Container>
        <Logo propLink propMargin={20} />
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          {/* <S.Navigation
            json={navigation}
            link={Link}
            activeClassName="active"
          /> */}
          <S.Nav className="ml-auto" navbar>
            <NavItem>
              <Link to="/" activeClassName="active">Home</Link>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <Link to="/quem-somos" activeClassName="active">Quem Somos</Link>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link to="/quem-somos" activeClassName="active">Empresa</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/quem-somos#equipe" activeClassName="active">Nossa Equipe</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <Link to="/servicos" activeClassName="active">Nossos Serviços</Link>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link to="/servicos/ultrassonografia-abdominal" activeClassName="active">Ultrassonografia Abdominal</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/servicos/ultrassonografia-gestacional" activeClassName="active">Ultrassonografia Gestacional</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/servicos/ultrassonografia-ocular" activeClassName="active">Ultrassonografia Ocular</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/servicos/ultrassonografia-cervical" activeClassName="active">Ultrassonografia Cervical</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/servicos/ultrassonografia-doppler-hepatica" activeClassName="active">Ultrassonografia Doppler Hepática</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/servicos/ultrassonografia-doppler-renal" activeClassName="active">Ultrassonografia Doppler Renal</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/servicos/ecodopplercardiograma" activeClassName="active">Ecodopplercardiograma</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/servicos/eletrocardiograma" activeClassName="active">Eletrocardiograma</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <Link to="/fale-conosco" activeClassName="active">Fale Conosco</Link>
            </NavItem>
          </S.Nav>
        </Collapse>
      </S.Container>
    </Navbar>
  );
}

export default Header;
