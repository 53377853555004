import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import * as S from './styled'

const Logo = ({ propLink, propMargin }) => {
  const { logoImage } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 170, height: 94) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  if (propLink) {
    return (
      <S.Link margin={propMargin} to="/">
        <S.LogoWrapper fixed={logoImage.childImageSharp.fixed} />
      </S.Link>
    )
  } else {
    return (
      <S.LogoWrapper margin={propMargin} fixed={logoImage.childImageSharp.fixed} />
    )
  }
}

Logo.defaultProps = {
  propLink: false,
  propMargin: 0,
}

Logo.propTypes = {
  propLink: PropTypes.bool,
  propMargin: PropTypes.number,
}

export default Logo
