import styled from 'styled-components'
import {Row as bRow} from 'reactstrap'
import media from 'styled-media-query'

export const Footer = styled.footer`
  padding: 3rem 0;
  background: #409c8c;
  a, a:hover {
    color: inherit;
  }
  ul {
    color: rgba(0, 0, 0, 0.5);
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      a, a:hover {
        color: inherit;
      }
    }
  }

  ${media.lessThan('small')`
    padding: 2rem 0;
  `}
`

export const Row = styled(bRow)`
  display: flex;
  justify-content: space-between;
  color: white;

  ${media.lessThan('small')`
    justify-content: center;
  `}
`

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  ${media.lessThan('small')`
    margin: 10px;
    text-align: center;
  `}
`

export const Developer = styled.small`
  font-weight: 100;

  a {
    font-weight: bold;
  }
`